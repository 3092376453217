<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">结算中心</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">公示名单</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="
            operationControl
            flexdc
            operationControlCurr
            operationControlWidth
          " style="align-items: flex-start">
          <div class="searchbox" style="width: 100%;margin-bottom: 10px;">
            <div>
              <div title="公示区域" class="searchboxItem searchboxItems">
                <span class="itemLabel">公示区域:</span>
                <el-cascader clearable v-model="ruleForm.publicityAreaId" placeholder="请选择公示区域" :options="areatreeList"
                  :props="propsarea" size="small"></el-cascader>
              </div>
              <div title="公示开始时间" class="searchboxItem searchboxItems">
                <span class="itemLabel" style="min-width:7rem">公示开始时间:</span>
                <el-date-picker v-model="ruleForm.startDate" placeholder="请选择公示开始时间" type="daterange" size="small"
                  value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                </el-date-picker>
              </div>
              <div title="公示结束时间" class="searchboxItem searchboxItems">
                <span class="itemLabel" style="min-width:7rem">公示结束时间:</span>
                <el-date-picker v-model="ruleForm.endDate" placeholder="请选择公示结束时间" type="daterange" size="small"
                  value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                </el-date-picker>
              </div>
              <el-button type="primary" class="bgc-bv" round @click="() => {
                getData();
              }
                ">查询</el-button>
              <el-button type="primary" class="bgc-bv" round @click="addOrEdit()">新增</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" fixed :index="indexMethod" />
              <el-table-column label="公示区域" align="left" show-overflow-tooltip prop="areaName" min-width="150" />
              <el-table-column label="公示名称" align="left" show-overflow-tooltip prop="publicityName" min-width="150" />
              <el-table-column label="业务年度" align="left" show-overflow-tooltip prop="businessYearStr" min-width="100" />
              <el-table-column label="公示日期" align="left" show-overflow-tooltip prop="publicityDate" width="180">
                <template slot-scope="{row}">
                  {{ row.publicityDateStart }} - {{ row.publicityDateEnd }}
                </template>
              </el-table-column>
              <el-table-column label="企业数量" align="left" show-overflow-tooltip prop="publicityCompanyNum"
                min-width="100" />
              <el-table-column label="人数" align="left" show-overflow-tooltip prop="publicityPersonNum" min-width="100" />
              <el-table-column label="金额" align="left" show-overflow-tooltip prop="publicityAmount" min-width="100" />
              <el-table-column label="操作人" align="left" show-overflow-tooltip prop="updateIdStr" min-width="100">
                <template slot-scope="{row}">
                  {{ row.updateIdStr || row.createIdStr }}
                </template>
              </el-table-column>
              <el-table-column label="操作时间" align="left" show-overflow-tooltip prop="updateTime" min-width="150">
                <template slot-scope="{row}">
                  {{ row.updateTime || row.createTime }}
                </template>
              </el-table-column>
              <el-table-column label="网址链接" align="left" show-overflow-tooltip prop="publicityUrl" min-width="150" />
              <el-table-column label="操作" align="center" width="150" fixed="right">
                <template slot-scope="scope">
                  <div>
                    <el-button type="text" size="mini" style="padding: 0 5px" @click="addOrEdit(scope.row)">编辑</el-button>
                    <el-button type="text" size="mini" style="padding: 0 5px"
                      @click="deleteproject(scope.row)">删除</el-button>
                    <el-button type="text" size="mini" style="padding: 0 5px"
                      @click="Exportstudent(scope.row.fdPublicityId)">导入</el-button>
                    <el-button type="text" size="mini" style="padding: 0 5px"
                      @click="checkTheList(scope.row)">查看名单</el-button>
                    <el-button type="text" size="mini" style="padding: 0 5px" @click="copyUrl(scope.row)"
                      :disabled="!scope.row.publicityUrl">复制链接</el-button>
                  </div>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <!-- 新增/编辑公示信息 -->
    <el-dialog :title="addAndEditDialogData.dialogTitle" :visible.sync="addAndEditDialogData.dialogState"
      v-if="addAndEditDialogData.dialogState" :close-on-click-modal="false" size="small" width="500px">
      <el-form ref="addAndEditDialogData" :model="addAndEditDialogData" label-width="120px" :rules="detailRules">
        <el-form-item label="公示名称" prop="publicityName">
          <el-input v-model="addAndEditDialogData.publicityName" placeholder="请输入公示名称" clearable :maxlength="50"
            show-word-limit size="small"></el-input>
        </el-form-item>
        <el-form-item label="业务年度" prop="businessYear">
          <el-date-picker v-model="addAndEditDialogData.businessYear" type="year" format="yyyy" value-format="yyyy"
            size="small" :picker-options="timeRange" placeholder="选择年">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="公示区域" prop="publicityAreaId">
          <el-cascader clearable v-model="addAndEditDialogData.publicityAreaId" placeholder="请选择公示区域"
            :options="areatreeList" :props="propsarea" size="small"></el-cascader>
        </el-form-item>
        <el-form-item label="公示日期" prop="publicityDate">
          <el-date-picker clearable size="small" v-model="addAndEditDialogData.publicityDate" type="daterange"
            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
            value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
        <el-form-item label="网址链接" prop="publicityUrl">
          <el-input v-model="addAndEditDialogData.publicityUrl" placeholder="请输入网址链接" clearable size="small"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addAndEditDialogData.dialogState = false" size="small">取 消</el-button>
        <el-button type="primary" @click="addAndEditConfirm" size="small">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 导入企业名单 -->
    <el-dialog title="导入企业名单" :visible.sync="centerDialogVisible" v-if="centerDialogVisible" @close="closeDocsDialog"
      class="exportdialog" width="50%">
      <el-form ref="ruleForm" :model="ruleForm" label-width="7rem" class="commodity-form">
        <el-form-item label="下载模板" class="form-item">
          <el-button type="text" @click="downloadExcelgs">公示名单模板.xlsx</el-button>
        </el-form-item>
        <el-form-item label="上传企业" class="form-item">
          <el-upload class="upload-demo upload-workers" style="margin-top: 6px;" :action="actionUrl"
            :on-error="handleError" :on-success="handleSuccess" :on-change="uploadChangeNew" :show-file-list="false"
            :auto-upload="false">
            <el-button class="bgc-bv" style="font-size: 12px; width: 80%">浏览</el-button>
          </el-upload>
          <p v-if="fileName" style="margin-top: 10px; margin-left: 10px">
            当前选择文件：
            <span style="color: #f46173">{{ fileName }}</span>
          </p>
          <p v-else style="margin-top: 10px; margin-left: 10px">
            未选择文件
          </p>
          <div>
            <el-button class="bgc-bv" size="mini" style="margin-top: 10px; height: 35px" :disabled="doExportInDisabled"
              @click="doExport">开始导入</el-button>
          </div>
          <div style="margin-top: 10px" v-if="progressVal > 0">
            <el-progress :text-inside="true" :stroke-width="20" :percentage="progressVal"></el-progress>
          </div>
        </el-form-item>
        <el-form-item label="错误数据" class="form-item">
          <div class="df studentNum">
            <span>导入已完成，共:{{ totalNum }}个企业;</span><span>成功:{{ correctNum }}个;</span><span>失败:{{ errorNum }}个;</span>
          </div>
          <div>
            <el-button class="bgc-bv" style="margin-top: 6px; height: 35px" size="mini" :disabled="errorNum == '0'"
              @click="doExportError">导出错误数据</el-button>
          </div>
          <div style="margin-top: 15px; color: #dd1d35" v-if="errorNum != 0">
            有导入失败企业，请导出错误数据，将导入失败企业调整后重新上传
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "financialSettlement_publicity_publicity",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      ruleForm: {
        publicityAreaId: "",//公示区域
        startDate: "",//公示开始日期
        endDate: "",//公示结束日期
      },
      areatreeList: [],//公示区域list
      propsarea: {
        value: "value",
        label: "label",
        disabled: "disabled",
        emitPath: false,
        checkStrictly: true,
      },
      addAndEditDialogData: {
        //弹窗数据
        dialogState: false, // dialog状态
        dialogTitle: "", // 弹框标题
        publicityAreaId: "", // 公示区域id
        publicityName: "", // 公示名称
        businessYear: "", // 业务年度
        publicityDate: "", // 公示日期
        publicityUrl: "", // 网址链接
      },
      detailRules: {
        publicityAreaId: [
          { required: true, message: "请选择公示区域", trigger: "change" },
        ],
        publicityName: [
          { required: true, message: "请输入公示名称", trigger: "blur" },
        ],
        businessYear: [
          { required: true, message: "选择业务年度", trigger: "change" },
        ],
        publicityDate: [
          { required: true, message: "请选择公示日期", trigger: "change" },
        ],
        publicityUrl: [
          { required: true, message: "请输入网址链接", trigger: "blur" },
        ],
      },
      // 限制业务年度的时间范围
      timeRange: {
        disabledDate: time => {
          // const date = new Date();    // 获取当前时间
          // const year = date.getFullYear();    // 获取当前年份
          // const minYear = (year - 10).toString();   // 当前年减10年
          // const maxYear = (year + 1).toString();   // 当前年加1年
          const timeYear = time.getFullYear();    // 当前年份
          const eYear = timeYear.toString();
          return eYear < '2020' || eYear > '2030';  // 小于最小年份，大于最大年份的禁用
        }
      },
      // 导入
      centerDialogVisible: false,
      fileName: "",
      fileKey: "",
      doExportInDisabled: true, //开始导入按钮禁用
      progressVal: 0,
      numText: '',
      totalNum: "0",
      errorNum: "0",
      correctNum: "0",
      errorExcelUrl: '',
    };
  },
  created() {
    this.getareatree()
  },
  computed: {},
  mounted() { },
  methods: {
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        let data = ret.data || [];
        data.map((el) => {
          if (el.children) {
            el.children.map((els) => {
              els.disabled = false;
              if (els.children) {
                els.children.map((els1) => {
                  els1.disabled = false;
                });
              }
            });
          }
        });
        this.areatreeList = data;
      });
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      // 公示区域
      if (this.ruleForm.publicityAreaId) {
        params.publicityAreaId = this.ruleForm.publicityAreaId;
      }
      // 公示开始日期
      if (this.ruleForm.startDate?.length > 0) {
        params.publicityStartDateStart = this.ruleForm.startDate[0];
        params.publicityStartDateEnd = this.ruleForm.startDate[1];
      }
      // 公示结束日期
      if (this.ruleForm.endDate?.length > 0) {
        params.publicityEndDateStart = this.ruleForm.endDate[0];
        params.publicityEndDateEnd = this.ruleForm.endDate[1];
      }
      this.doFetch(
        {
          url: "/biz/new/publicity/pageList",
          params,
          pageNum,
        },
        true
      );
    },
    // 新增或编辑
    addOrEdit(row) {
      this.emptyData();
      if (row) {
        Object.keys(this.addAndEditDialogData).forEach((key) => {
          this.addAndEditDialogData[key] = row[key];
          if (key == 'publicityDate' && row['publicityDateStart']) {
            this.addAndEditDialogData['publicityDate'] = [row['publicityDateStart'].replaceAll("/", "-"), row['publicityDateEnd'].replaceAll("/", "-")];
          }
        });
        this.addAndEditDialogData.businessYear = String(row?.businessYear ?? "");
        this.addAndEditDialogData.dialogTitle = "查看公示信息";
        this.addAndEditDialogData.fdPublicityId = row.fdPublicityId; //id
      } else {
        this.addAndEditDialogData.dialogTitle = "新增公示信息";
        this.addAndEditDialogData.fdPublicityId = ""; //id
      }

      this.addAndEditDialogData.dialogState = true;
    },
    // 新增或编辑公示信息提交
    addAndEditConfirm() {
      this.$refs.addAndEditDialogData.validate((valid) => {
        if (valid) {
          const { fdPublicityId } = this.addAndEditDialogData
          let url
          if (fdPublicityId) {
            url = '/biz/new/publicity/modify'
          } else {
            url = '/biz/new/publicity/save'
          }
          let data = {
            ...this.addAndEditDialogData,
            publicityDateStart: this.addAndEditDialogData.publicityDate[0],
            publicityDateEnd: this.addAndEditDialogData.publicityDate[1],
          }
          this.$post(url, data, 5000)
            .then((res) => {
              if (res.status == 0) {
                this.addAndEditDialogData.dialogState = false;
                this.$message.success(res.message);
                this.getData(-1);
                fdPublicityId || this.Exportstudent(res.data.fdPublicityId)
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          return false;
        }
      });
    },
    // 还原新增编辑数据
    emptyData() {
      this.addAndEditDialogData = this.$options.data().addAndEditDialogData;
    },
    //删除
    deleteproject(row) {
      this.doDel({
        url: "/biz/new/publicity/delete",
        msg: "删除公示信息时,其导入的企业名单将一起删除,且数据不可逆,确定要删除该条公示记录吗?",
        ps: {
          type: "post",
          data: {
            fdPublicityId: row.fdPublicityId,
          },
        },
      });
    },
    // 复制到剪贴板
    copyUrl(data) {
      let url = data.publicityUrl;
      let oInput = document.createElement("input");
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$message({
        message: "已成功复制到剪切板",
        type: "success",
      });
      oInput.remove();
    },
    checkTheList(row) {
      this.$router.push({
        path: '/web/financialSettlement/checkTheList',
        query: {
          fdPublicityId: row.fdPublicityId
        }
      })
    },
    /* 导入 */
    Exportstudent(fdPublicityId) {
      this.fdPublicityId = fdPublicityId
      this.centerDialogVisible = true;
    },
    downloadExcelgs() {
      /**
       * 下载模板
       * @param param 参数
       */
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = "/static/publicitylist.xlsx";
      link.setAttribute("download", "公示名单模板.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    // 导入企业
    uploadChangeNew(file) {
      this.fileName = file.name;
      let size = file.size / 1024 / 1024;
      // let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "XLSX";
      const isxlsx = extension === "xlsx";
      const isXLS = extension === "XLS";
      const isxls = extension === "xls";
      if (!isXLSX && !isxlsx && !isXLS && !isxls) {
        this.$message.error("只能上传后缀是.xlsx或者.xls的文件");
        return;
      }
      if (size > 3) {
        this.$message.error("文件大小不能超过3M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);

      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          // console.log(result);
          this.fileKey = result.data.fileKey;
          this.doExportInDisabled = false;
          // this.fileName = result.data.fileKey;
        })
        .catch((err) => {
          return;
        });
    },
    doExport() {
      const el = this.$refs.ruleForm;
      el.validate((valid) => {
        if (valid) {
          if (this.fileKey == "") {
            this.$message({
              message: "请选择文件",
              type: "warning",
            });
          } else {
            this.doExportInDisabled = true;
            if (this.fileKey) {
              const parmar = {
                excelUrl: this.fileKey,
                fdPublicityId: this.fdPublicityId,
              };
              this.$post("/biz/new/publicity/importPublicityList", parmar, 5000)
                .then((res) => {
                  if (res.status == 0) {
                    if (res.status == 0) {
                      this.doProgress(res.message);
                      this.doExportInDisabled = false;
                    }
                  }
                })
                .catch(() => {
                  return;
                });
            } else {
              this.doExportInDisabled = false;
              this.$message({
                message: "请选择文件",
                type: "warning",
              });
            }
          }
        } else {
          this.$message({
            type: "warning",
            message: "请输入必填项",
          });
        }
      });

    },
    //获取导入进度
    doProgress(batchId) {
      this.$post(
        "/biz/new/publicity/importPublicity/progress",
        { batchId },
        5000
      ).then((ret) => {
        if (!ret.data.status) {
          this.progressVal = ret.data.progress;
          setTimeout(() => {
            this.doProgress(batchId);
          }, 2000);
        } else {
          if (ret.data.message) {
            this.$message({
              type: "error",
              message: ret.data.message,
            });
            this.numText = ret.data.message
            this.totalNum = ret.data.totalNum;
            this.errorNum = ret.data.errorNum;
            this.correctNum = ret.data.correctNum;
            this.errorExcelUrl = ret.data.excelUrl
          } else {
            this.numText = ret.data.message
            this.totalNum = ret.data.totalNum;
            this.errorNum = ret.data.errorNum;
            this.correctNum = ret.data.correctNum;
            this.importType = ret.data.importType;
            this.batchId = ret.data.batchId;
            this.progressVal = ret.data.progress;
            this.$message({
              type: "success",
              message: "导入成功",
            });
          }
        }
      });
    },
    //导出错误数据
    doExportError() {
      if (this.batchId == "" || this.fileKey == "") {
        this.$message({
          message: "还没有导入数据，请先导入文档",
          type: "warning",
        });
      } else {
        // this.$post(
        //     "/biz/project/user/v2/error/export",
        //     { batchId: this.batchId, projectId: this.projectId },
        //     5000
        // ).then((res) => {
        //   if (res.status == 0) {
        window.open(this.errorExcelUrl);
        // }
        // });
      }
    },
    closeDocsDialog() {
      // this.centerDialogVisible = false;
      this.newvalues = [];
      this.fileKey = "";
      this.fileName = "";
      this.progressVal = 0;
      this.totalNum = 0;
      this.errorNum = 0;
      this.correctNum = 0;
      this.doExportInDisabled = true;
      // this.doExportInDisabled = false;
      this.dialogFormVisible = false;
      this.ruleForm = {
        areaId: "",
        excelUrl: "",
        fileName: "",
      };
      this.getData(-1);
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scoped>
.searchbox>div .el-input {
  flex: 1;
}

.searchBox {
  padding: 0;

  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}

.searchboxItems {
  display: flex;
  align-items: center;

  .itemLabel {
    min-width: 5rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}

.operationControlCurr {
  >div {
    width: 100%;
    justify-content: space-between;
  }

  .itemLabel {
    min-width: 5rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}
</style>
<style lang="less" scoped>
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;

    &:before {
      content: "\e6df" !important;
    }
  }

  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;

    .el-tabs__content {
      flex: 1;
    }
  }
}

.Classitem {
  .el-form-item__content {
    display: flex;
  }
}

.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
  background-color: transparent;
}

.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}

.btnBox {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.exportdialog {
  .el-dialog__title {
    font-size: 16px;
  }

  .export-box {
    width: 100%;
    margin-bottom: 20px;

    h1 {
      padding: 0 5px 10px;
      display: flex;
      align-items: flex-end;
      font-size: 40px;
      border-bottom: 1px dashed #ccc;

      span {
        margin-left: 20px;
        font-size: 14px;
      }
    }

    >div {
      padding-left: 30px;
    }

    .el-button.is-disabled,
    .el-button.is-disabled:focus,
    .el-button.is-disabled:hover {
      color: #fff;
      background: #c3c3c3;
      border: none;
    }
  }
}

.docsDialog {
  .el-form-item {
    margin-bottom: 0.5rem;
  }
}

.studentNum {
  margin: 20px 0 10px;

  span {
    margin: 0 10px;
  }
}

.exportdialog {
  /deep/.upload-workers {
    height: 30px;

    .el-upload {
      height: 30px !important;
      width: 97px;
      border: none !important;

      .el-button {
        padding: 5px 0;
      }
    }
  }
}
</style>

